<template>
  <div>
    <div class="title">审批表单</div>

    <el-divider></el-divider>

    <div class="table_title">使用说明</div>
    <div class="tip">
      1、在这里您可以管理企业的审批表单模板，可以自定义创建，或者复用模板创建。<br />
      2、调整审批分组和排序，对应员工在发起审批界面所看到的分组和顺序。
    </div>
    <div class="btns">
      <div
        class="custom_button plain no_select"
        @click="$router.push({ name: 'ApprovalFormsCate' })"
      >
        编辑分组
      </div>
      <div
        class="custom_button no_select"
        @click="$router.push({ name: 'ApprovalFormsEditor' })"
      >
        <!-- ApprovalFormsEditor -->
        新建审批表单
      </div>
    </div>

    <!-- table -->
    <div v-for="(cate, index) in cateList" :key="cate.id">
      <div class="table_title">{{ cate.title || "无标题" }}</div>
      <el-table
        :data="tableData[index]"
        :header-cell-style="tableHeaderStyle"
        :cell-style="tableCellStyle"
        style="width: 100%"
      >
        <el-table-column prop="cover_img" label="图标">
          <template slot-scope="scope">
            <el-image
              :src="scope.row.cover_img"
              fit="cover"
              style="width: 50px; height: 50px"
            ></el-image>
          </template>
        </el-table-column>
        <el-table-column prop="id" label="id"> </el-table-column>
        <el-table-column prop="title" label="名称"> </el-table-column>
        <el-table-column prop="desc" label="描述"> </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <div class="operations">
              <div
                @click="
                  $router.push({
                    name: 'ApprovalFormsEditorId',
                    params: { id: scope.row.id },
                  })
                "
              >
                编辑
              </div>
              <div @click="del(scope.row.id, index)" style="color: #4db9d5">
                删除
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        layout="prev, pager, next"
        :total="page[index].total"
        :page-size="page[index].size"
        :current-page.sync="page[index].page"
        @current-change="loadData(index)"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      host: process.env.VUE_APP_BASE_API,
      tableData: [],
      cateList: [],
      page: [],

      tableHeaderStyle: {
        backgroundColor: "#F5F8FA",
        textAlign: "center",
      },
      tableCellStyle: {
        textAlign: "center",
      },
    };
  },
  created() {
    let loading = this.$loading();
    this.getApproverCateList({ page: 1, size: 1000 })
      .then((res) => {
        loading.close();
        console.log(res);
        this.cateList = res.data.data.list;
        for (let i = 0; i < this.cateList.length; i++) {
          this.page.push({
            size: 5,
            page: 1,
            total: 0,
          });
          this.loadData(i);
        }
      })
      .catch((e) => {
        loading.close();
        console.error(e);
      });
  },
  methods: {
    ...mapActions("attendance", [
      "getApproverCateList",
      "getApproverList",
      "getDelApprover",
    ]),

    loadData(index) {
      let loading = this.$loading();
      this.getApproverList({
        page: this.page[index].page,
        size: this.page[index].size,
        cate_id: this.cateList[index].id,
      })
        .then((res) => {
          let { list, total } = res.data.data;
          console.log(list, "分页");
          this.$set(this.tableData, index, list);

          this.page[index].total = total;
          loading.close();
          //   console.log(this.tableData, this.page, total);
        })
        .catch((e) => {
          loading.close();
          this.$message.error(e);
        });
    },

    del(id, index) {
      this.$confirm("此操作不可逆转，确定删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let loading = this.$loading();
        this.getDelApprover({ id })
          .then((res) => {
            console.log(res);
            loading.close();
            this.$message.success(res.data.msg);
            this.loadData(index);
          })
          .catch((e) => {
            console.log(e);
            loading.close();
            this.$message.error(e.msg);
          });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  font-size: 14px;
  font-weight: bold;
  color: #1c2438;
}

.tip {
  color: #495060;
  line-height: 16px;
  font-size: 12px;
  margin-bottom: 40px;
}

.btns {
  display: flex;
  justify-content: right;

  > div {
    margin: 0 10px;
  }
}

.el-table {
  margin-bottom: 60px;
}

.operations {
  display: flex;
  align-items: center;
  justify-content: center;

  > div {
    font-size: 14px;
    color: #406bff;
    cursor: pointer;

    &:not(:last-child) {
      margin-right: 13px;
    }
  }
}
</style>
